import "./intro.css";

import { FaAward } from "react-icons/fa";
import React from "react";
import { VscFolderLibrary } from "react-icons/vsc";
import img from '../../assets/PHOTO.JPG' 


const Intro = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={img} alt="Rasif Taghizade" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Hands on Experience</h5>
              <small>1+ year</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>5+ Completed Projects</small>
            </article>
          </div>
          <p>
          I am a dedicated software developer with a strong foundation in full-stack development, encompassing frontend and backend integration, cloud technologies, and mobile app development using Flutter. My work involves crafting dynamic, responsive web and mobile applications that deliver seamless user experiences. I am passionate about utilizing modern technologies to solve complex challenges and create innovative solutions that meet user needs. My approach combines deep technical knowledge with a commitment to clean, efficient code and meticulous attention to detail. Whether developing real-time communication platforms or service-oriented applications, I am focused on delivering high-quality, scalable solutions that drive business success and enhance user satisfaction. I thrive in collaborative environments and continually seek opportunities to learn and grow.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default Intro;
