import './contact.css';
import React, { useRef, useState } from 'react';
import { MdOutlineEmail } from 'react-icons/md';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [message, setMessage] = useState(false);
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });
  const [errors, setErrors] = useState({
    user_name: false,
    user_email: false,
    message: false,
  });
  const formRef = useRef();

  const handleInputChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form data
    let formIsValid = true;
    const newErrors = { user_name: false, user_email: false, message: false };

    if (formData.user_name.trim() === '') {
      newErrors.user_name = true;
      formIsValid = false;
    }

    if (formData.user_email.trim() === '') {
      newErrors.user_email = true;
      formIsValid = false;
    } else if (!isValidEmail(formData.user_email)) {
      newErrors.user_email = true;
      formIsValid = false;
    }

    if (formData.message.trim() === '') {
      newErrors.message = true;
      formIsValid = false;
    }

    setErrors(newErrors);

    if (formIsValid) {
      setMessage(true);
      emailjs
        .sendForm(
          'service_mg6toas', // Replace with the default service ID (usually 'service_k2qawqh')
          'template_ang6gnp', // Replace with the ID of your email template
          formRef.current,
          'vvJ6z4OyTlw2oPNLJ'
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
    }
  };

  const isValidEmail = (email) => {
    // Basic email validation pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h5>I do receive your messages and will respond asap if the valid email is provided :)</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>maheshlangote779@gmail.com</h5>
            <a href="mailto:maheshlangote779@gmail.com">Send a message</a>
          </article>
        </div>
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className={`form-group ${errors.user_name ? 'has-error' : ''}`}>
            <input
              type="text"
              placeholder="Your Full Name"
              name="user_name"
              value={formData.user_name}
              onChange={handleInputChange}
              required
            />
            {errors.user_name && <div className="error-message">Please enter your name.</div>}
          </div>
          <div className={`form-group ${errors.user_email ? 'has-error' : ''}`}>
            <input
              type="text"
              placeholder="Your Email"
              name="user_email"
              value={formData.user_email}
              onChange={handleInputChange}
              required
            />
            {errors.user_email && <div className="error-message">Please enter a valid email address.</div>}
          </div>
          <div className={`form-group ${errors.message ? 'has-error' : ''}`}>
            <textarea
              placeholder="Your message"
              rows="7"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              required
            ></textarea>
            {errors.message && <div className="error-message">Please enter your message.</div>}
          </div>
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
          {message && <span>Thank you for your message. get back to you as soon as possible. :)</span>}
        </form>
      </div>
    </section>
  );
};

export default Contact;