import "./portfolio.css";
import IMG2 from "../../assets/incognitos.png";
import IMG3 from "../../assets/dbk.png";
import IMG4 from "../../assets/chat.png";
import IMG5 from "../../assets/formcraft.png";
import IMG6 from "../../assets/storyforge.png";
import IMG7 from "../../assets/quiz.png";


import React from "react";

//Portfolio function
const Portfolio = () => {
  const soloProjects = [
    {
      id: 3,
      title: "StoryForge",
      img: IMG6,
      description: "Developed a responsive blogging platform with Vite, React, Tailwind CSS, Node.js, Express, and MongoDB, deployed on Vercel.",
      technologies: "React | Node | Express | MongoDb ",
      link: "https://storyforge-nine.vercel.app/",
      github: "https://github.com/Mahesh-Langote/blog-app-server",
    },
    {
      id: 1,
      title: "LiveLink",
      img: IMG4,
      description:
        "Developed a real-time chat application with seamless messaging, live updates, user-friendly UI, and secure data encryption",
      technologies: "React | Node | Express | MongoDb | Socket.io",
      link: "https://chat-client-psi-nine.vercel.app/",
      github: "https://github.com/Mahesh-Langote/chat-client.git",
    },
    {
      id: 7,
      title: "Quizzique",
      img: IMG7,
      description:
        "Quizzique is a dynamic quiz management tool that simplifies quiz creation, analysis, and sharing. It offers real-time analytics and easy customization for an engaging user experience.",
      technologies: "React | Node | Express | MongoDb ",
      link: "https://quiz-alpha-gamma.vercel.app/",
      github: "https://github.com/Mahesh-Langote",
    },
    {
      id: 6,
      title: "FormCraft",
      img: IMG5,
      description:
        "This innovative solution streamlines the information gathering process by presenting questions in a conversational format, improving user experience and response rates.Developed a real-time chat application with seamless messaging, live updates, user-friendly UI, and secure data encryption",
      technologies: "React | Node | Express | MongoDb ",
      link: "https://form-craft-alpha.netlify.app",
      github: "https://github.com/Mahesh-Langote/maheshlangote779-gmail.com_client_cuvette_final_evaluation_may",
    }, 
    {
      id: 4,
      title: "Incognitos Community Website",
      img: IMG2,
      description:
        "Incognitos Community Website is a platform for developers to share code snippets, experiment with code, and chat in real-time.",
      technologies: "React | Express | Node | MongoDB | CICD",
      link: "https://incognitos.online",
      github: "https://github.com/Mahesh-Langote",
    },
    {
      id: 5,
      title: "Website for DBK traders",
      img: IMG3,
      description: "Designed and developed a responsive website for DBK Metal Works and Exporters Private Limited, a leading metal works and exporters company.",
      technologies: "HTML | CSS | JavaScript | PHP | MySQL ",
      link: "https://dbktraders.com",
      github: "https://github.com/Mahesh-Langote",
    },
   
    
  ];

  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {soloProjects.map((pro) => (
          <article className="portfolio__item" key={pro.id}>
            <div className="portfolio__item-image">
              <img src={pro.img} alt={pro.title} />
            </div>
            <div className="portfolio__item-content">
              <h3>{pro.title}</h3>
              <p>{pro.description}</p>
              
              <p>{pro.technologies}</p>
            </div>
            <br /><br />
            <div className="portfolio__item-cta">
              <a
                href={pro.github}
                target="_blank"
                className="btn"
                rel="noreferrer"
              >
                GitHub
              </a>
              <a
                href={pro.link}
                target="_blank"
                className="btn btn-primary"
                rel="noreferrer"
              >
                Live Demo
              </a>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
